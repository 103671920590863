import React from "react"
import { Helmet } from "react-helmet"
import {
  AttributeSection,
  CTAButton,
  GetStartedButton,
} from "../commonComponents"
import { Layout } from "../components/Layout"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
  MindMap,
  ProductEngineeringIntegration,
} from "../integrationsUtils"

export default function Jira() {
  const SERVICE_NAME = "jira"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "100% 70px"
  const BACKGROUND_MOBILE_POSITION = "35% 50px"
  return (
    <Layout
      title="Streamline Your Operations By Integrating IrisAgent With Jira"
      keywords="Jira integration, Jira Salesforce integration, Jira Zendesk integration, product bugs"
      description="Optimize your customer support operations with seamless Jira integration provided by IrisAgent. Streamline your customer ticket resolution with enhance efficiency"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/jira/"
        />
      </Helmet>
      <main id="main">
        {/*  ======= Hero Section =======  */}
        <IntegrationHeroSection
          serviceName={SERVICE_NAME}
          titleIntegration={
            <>
              Link product issues <br /> to{" "}
              <span className="title-fucsia-color">support tickets</span>
            </>
          }
          descriptionIntegration={
            <>
              <span className="web-view-new-design-display">
                Automate two-way workflows to get a 360-degree
                <br />
                view of incidents and support tickets. Connect
                <br />
                Jira with your customer support processes.
              </span>
              <span className="mobile-view-new-design-display">
                Automate two-way workflows
                <br />
                to get a 360-degree view of
                <br />
                incidents and support tickets.
                <br />
                Connect Jira with your customer
                <br />
                support processes.
              </span>
            </>
          }
          sectionBackgroundSrc={BACKGROUND_SRC}
          sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
          sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
        />
        {/*  End Hero  */}
        {/*  ======= Cta Section =======  */}
        <IntegrationDarkSection
          label={
            <span className="integration-dark-section-title">
              <span className="title-fucsia-color">
                Collaborate seamlessly throughout
                <br />
              </span>
              your organization
            </span>
          }
          button={<CTAButton
            to="https://marketplace.atlassian.com/apps/1229507/irisagent"
            label={`FIND US ON ${SERVICE_NAME.toUpperCase()} MARKETPLACE`}
            target="_blank"
          />}
        />
        {/*  End Cta Section  */}

        {/*  ======= MindMap Section =======  */}
        <section
          id="intro-section"
          className="section-padding-left intro-bg-col"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div className="row">
            <MindMap
              colorLine={"#7879F1"}
              colorBox={"#F4F5FF"}
              imagen={"/img/genericCustomer.png"}
              icon={"/img/jiraIconBox.png"}
              iconDot={"/img/jiraDot.png"}
            />
          </div>
        </section>
        {/*  End MindMap Section  */}

        {/*  ======= Product Engineering Integrations Section =======  */}

        <ProductEngineeringIntegration
          imagen={"/img/jiraPEI.png"}
          icon={"/img/jiraMobileLogo.png"}
        />

        {/*  End Product Engineering Integrations Section  */}

        {/* //   <!-- ======= Attribute Section ======= --> */}
        <AttributeSection />
        {/* //   <!-- ======= END -- Attribute Section ======= --> */}
        {/*  ======= Cta Section =======  */}
        <IntegrationDarkSection
          label={
            <span className="integration-dark-section-title">
              <span className="web-view-new-design-display">
                <span className="title-fucsia-color">Respond faster</span> and{" "}
                <span className="title-fucsia-color">more empathetically</span>
                <br />
                to your customers
              </span>
              <span className="mobile-view-new-design-display">
                <span className="title-fucsia-color">Respond faster</span> and{" "}
                <span className="title-fucsia-color">
                  more
                  <br />
                  empathetically{" "}
                </span>
                to your customers
              </span>
            </span>
          }
          button={<GetStartedButton />}
          firstColClassName="col-md-10"
          secondColClassName="col-md-2"
        />
        {/*  End Cta Section  */}
        {/* <!-- ======= Cta Section ======= --> */}
        {/* <ContactSection></ContactSection> */}
        {/* <!-- End Cta Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}
